@font-face {
  font-family: minionRegular;
  src: url(./Fonts/MinionProRegular.otf);
}

@font-face {
  font-family: minionItalic;
  src: url(./Fonts/MinionProItalic.ttf);
}

@font-face {
  font-family: minionBold;
  src: url(./Fonts/MinionProBold.ttf);
}


body {
  margin: 0;
  padding: 0;
  font-family: "Hind", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
